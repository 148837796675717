<template>
  <div class="admin-guidance-playbooks">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        <!-- <a-button type="default" style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/guidance')" icon="left"></a-button> -->

        <admin-org-indicator></admin-org-indicator>

        Admin - Guidance - Playbooks
      </h1>
      <div class="actions">
        <a-button @click.prevent="loadPlaybooks" :disabled="isLoading" class="button-margin-left btn-rounded"
          icon="reload" size="large" type="default">Refresh</a-button>

        <a-button v-if="showCreateButton" @click.prevent="createPlaybook" class="button-margin-left btn-rounded"
          icon="plus" size="large" type="primary">Add</a-button>
      </div>
    </div>
    <!-- / Page Header -->

    <!-- List wrapper -->
    <div class="playbooks-list-wrapper">
    

      <div class="admin-guidance-context-selector-wrapper">
        <admin-guidance-context-selector v-if="!isLoading"></admin-guidance-context-selector>
      </div>

      <a-alert
        style="margin-bottom: 25px"
        message="A playbook allows you to add additional tasks to help responders and to remain compliant to policies and procedures."
      >
      </a-alert>

        <!-- Loading -->
        <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No playbooks -->
      <a-alert v-if="!isLoading && playbooksToShow.length == 0" type="info" message="No playbooks to show">
      </a-alert>
      <!-- / No playbooks -->

      <!-- Playbooks list -->
      <div class="playbooks-list" v-if="!isLoading && playbooksToShow.length" :key="ownerIdFilter">
        <a-row type="flex" :gutter="20">
          <a-col :span="colSpan" v-for="playbook in playbooksToShow" :key="playbook.id">
            <playbook-list-item :playbook="playbook" :tenant-id="tenantId" :org-id="selectedOrganisation.id"
              @selected="() => navigateToPlaybook(playbook)"></playbook-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Playbooks list -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PlaybookListItem from './Playbooks/PlaybookListItem.vue';
import AdminGuidanceContextSelector from "../../../components/Admin/Guidance/AdminGuidanceContextSelector.vue";
export default {
  components: { PlaybookListItem, AdminGuidanceContextSelector },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadPlaybooks();
  },

  watch: {
    selectedOrganisation() {
      this.loadPlaybooks();
    },
  },

  methods: {
    ...mapActions("adminPlaybooks", {
      loadPlaybooks: "loadPlaybooks",
    }),

    navigateToPlaybook(playbook) {
      this.$router.push("/admin/guidance/playbooks/" + playbook.id);
    },

    createPlaybook() {
      this.$router.push("/admin/guidance/playbooks/create");
    },
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    ...mapGetters("adminPlaybooks", {
      isLoading: "isLoading",
      playbooksToShow: "playbooksToShow"
    }),

    ...mapGetters("adminGuidance", {
      ownerIdFilter: 'ownerIdFilter'
    }),

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },

    showCreateButton() {
      return (!this.ownerIdFilter || this.ownerIdFilter == this.selectedOrganisation.id)
    }
  },
};
</script>

<style scoped lang="scss">
.playbook-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}

.admin-guidance-context-selector-wrapper {
  margin-bottom: 30px;
}
</style>